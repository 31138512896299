import { Link } from "react-router-dom";

export default function Tracking(){
    return(
<main className="main">
        
		{/* <!-- Start Breadcrumb
		============================================= --> */}
		<div className="site-breadcrumb" style={{backgroundImage: `url("assets/img/pictures/breadcrumb.jpg")`}}>
			<div className="container">
				<div className="site-breadcrumb-wpr">
					<h2 className="breadcrumb-title">Tracking</h2>
					<ul className="breadcrumb-menu clearfix">
                    <li><Link to="/">Home</Link></li>
						<li className="active">Tracking</li>
					</ul>
				</div>
			</div>
		</div>
        {/* <!-- End Breadcrumb --> */}
        
        <div className="checkout-area bg de-padding">
        	<div className="container">
				<div className="checkout-wrapper pos-rel">
					<form className="checkout">
						<h2>
							<i className="fas fa-shipping-fast"></i>
							Track Your Shipment
						</h2>
						<div className="street">
							<label htmlFor="name">Tracking number</label>
							<input type="email" name="email" />
						</div>
						<div className="street">
							<label htmlFor="name">Mobile Number</label>
							<input type="text" name="address" />
						</div>
						<div className="btns">
							<button disabled>Track Now</button>
						</div>
					</form>
				</div>
			</div>
        </div>
		
		{/* <!-- Start Step
		============================================= --> */}
		<div className="step-area de-padding">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 offset-xl-2">
						<div className="site-title text-center">
							<span className="hero-sub-title mb-20">
								<span className="hero-line"></span>
								How it work
							</span>
							<h2 className="heading-1">
								We follow great process
							</h2>
							<p className="mb-0">
							We offer a fully integrated service from a fully committed and equipped team, capable of meeting
every related need. Regardless of your requirement, our approach remains the same. Our first priority is to gain
a thorough understanding of the requirements of your business and then efficiently dealing with each task as
they present themselves. A dedicated team led by the directors to services all clients.
							</p>
						</div>
					</div>
				</div>
				<div className="step-wpr grid-4">
					<div className="step-box">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/dispatch-note.png" className="step-note-icon" alt="no" />
									<span className="step-num">01.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Dispatched</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box mt-50">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/delivery-truck.png" className="step-note-icon" alt="no" />
									<span className="step-num">02.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-2.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Dispatched</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/cost-per-click.png" className="step-note-icon" alt="no" />
									<span className="step-num">03.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Delivered</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
					<div className="step-box mt-50">
						<div className="step-icon">
							<div className="step-elm">
								<div className="step-elm-elm">
									<img src="assets/img/shape/Shape.png" alt="no" />
									<img src="assets/img/icon/pay.png" className="step-note-icon" alt="no" />
									<span className="step-num">04.</span>
								</div>
								<div className="step-wavy-arrow">
									<img src="assets/img/shape/wavy-arrow-1.png" className="opacity-0" alt="no" />
								</div>
							</div>
						</div>
						<div className="step-desc">
							<h4 className="heading-4">Order Payment</h4>
							<p className="mb-0">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- End Step --> */}
		
	</main>	
    )
}