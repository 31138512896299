import { Link } from "react-router-dom";

import "./index.css"; // Import the styles

const FloatingButton = ({ link, text }) => {
  return (
    <Link to={link} className="floating-button">
      {text}
    </Link>
  );
};

export default FloatingButton;
