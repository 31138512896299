import { Link } from "react-router-dom";

export default function NotFound(){
    return (
        <main class="main d-flex align-items-center justify-content-center h-100vh">
            {/* <!-- Start Faq
            ============================================= --> */}
            <div class="page-not-found de-padding">
                <div class="container">
                    <div class="page-not-wpr grid-2">
                        <div class="page-not-left">
                            <img src="assets/img/vector/404.webp" class="d-block text-center" alt="thumb" />
                        </div>
                        <div class="page-not-right d-flex align-items-center">
                            <div class="page-not-righ-ele">
                                <h2 class="headin-1">404</h2>
                                <h4 class="heading-4">Look like you're lost</h4>
                                <p class="mb-40">the page you are looking for not Found!</p>
                                <Link to="/" class="btn-3">Go Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Faq --> */}
        </main>	
    )
}