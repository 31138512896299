import { Routes, Route } from 'react-router-dom';
import Home from '../home';
import NotFound from '../shared/notFound';
import About from '../about';
import Contact from '../contact';
import Tracking from '../tracking';

export default function Switch(){
    return(
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/tracking" element={<Tracking />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}