import { BrowserRouter as Router } from 'react-router-dom';

import Footer from './components/shared/footer';
import Header from './components/shared/header';
import ScrollToTop from './components/ScrollToTop';
import Switch from './components/shared/switch';
import FloatingButton from './components/floatingButton';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <FloatingButton link="/tracking" text="Track Now" />
      <Switch />
      <Footer />
    </Router>
  );
}

export default App;
